import React, { useEffect, useMemo } from 'react';
import { Modal, Row, Col } from 'react-bootstrap'
import { useModalHandler } from '../../../helper/custom_hook';
import { useDispatch } from "react-redux";
import CustomSelect from '../../../components/CustomSelect';
import { fee } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { TableResponsive } from '../../../components/common/TableResponsive';
import { useNavigate } from 'react-router-dom';
import { current_dues, given_fee_current_dues } from '../../../helper/dues_calculators';

function FeesPaymentModal({ row_data, setDuesData }) {
    const { status, toggleModal } = useModalHandler();
    const dispatch = useDispatch();
    useEffect(() => {
        if (status) setTotalPayableAmount();
    }, [status])
    const handleAmountChange = e => setTotalPayableAmount();
    const setTotalPayableAmount = () => {
        const deposit_inputs = document.getElementsByClassName('deposit_input')
        const totalPayable = Array.from(deposit_inputs).reduce((total, input) => total + parseFloat(input.value || 0), 0);
        document.getElementById('total_payable_amount').value = totalPayable;
    }
    const navigate = useNavigate();
    const handleViewInvoice = id => navigate('/view-invoice', { state: { invoiceId: id } });
    const handlePaymentSubmit = e => {
        e.preventDefault();
        dispatch(setPreloader({ loader: true, message: 'Please wait ...' }))
        const formData = new FormData(e.target);
        const paymentData = {
            payment_mode: formData.get('payment_mode'),
            user_id: row_data?.id,
            payment_details: row_data?.due_fee?.map(d => ({ due_fee_id: d.id, paid_amt: document.getElementById('deposit_' + d.id)?.value || 0 }))
        }
        fee.payment(paymentData)
            .then(r => {
                setDuesData(s => s.map(f => f.id == r.data.fee_dues.id ? r.data.fee_dues : f));
                swal.success(r.message, 'Done');
                toggleModal();
                return r.data
            })
            .then(data => handleViewInvoice(data?.invoice?.id))
            .catch(e => swal.error(e.response ? e.response.data.message : e.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: '' })))

    }
    const handlePaymentSubmitListView = e => {
        e.preventDefault();
        swal.info('Unsupported Format', 'Feature Unavailable on Phone, Please Use a Computer');
    }
    const tableColumns = useMemo(() => [
        {
            Header: "Name",
            DataClass: 'text-center',
            HeaderClass: 'text-center',
            accessor: 'name'
        },
        {
            Header: "Amount",
            DataClass: 'text-center',
            HeaderClass: 'text-center',
            Cell: cell => {
                const row = cell.row.original;
                return <span className="badge p-2 badge-outline-warning">{row.imposed_amount}</span>
            }
        },
        {
            Header: "Total paid",
            DataClass: 'text-center',
            HeaderClass: 'text-center',
            Cell: cell => {
                const row = cell.row.original;
                return <span>{row.fees_payment.reduce((acc, f) => acc + f?.paid_amt, 0)}</span>
            }
        },
        {
            Header: "Current Dues",
            DataClass: 'text-center bg-soft-danger',
            HeaderClass: 'text-center bg-soft-danger',
            Cell: cell => {
                const row = cell.row.original;
                return <span>{given_fee_current_dues(row)}</span>
            }
        },
        {
            Header: "Payment",
            DataClass: 'text-center',
            HeaderClass: 'text-center',
            Cell: cell => {
                const row = cell.row.original;
                return <div className='d-flex align-items-center justify-content-center'>
                    <input type='number' id={'deposit_' + row.id} step={0.01} defaultValue={given_fee_current_dues(row)} onChange={e => handleAmountChange(e)} className='form-control deposit_input' style={{ width: '120px' }} />
                </div>
            }
        },
        {
            Header: "List",
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                return (
                    <div>
                        <h5>{row.name}</h5>
                        <p className='font-bold d-flex justify-content-between align-items-center'>
                            <span>Fees Amount :</span>
                            <span>{row.imposed_amount}</span>
                        </p>
                        <p className='font-bold d-flex justify-content-between align-items-center'>
                            <span>Total paid :</span>
                            <span>{row.fees_payment.reduce((acc, f) => acc + f?.paid_amt, 0)}</span>
                        </p>
                        <p className='font-bold d-flex justify-content-between align-items-center'>
                            <span>Current Dues :</span>
                            <span>{given_fee_current_dues(row)}</span>
                        </p>
                        <p className='font-bold d-flex justify-content-between align-items-center'>
                            <span>Payment :</span>
                            <input type='number' id={'deposit_' + row.id} step={0.01} defaultValue={given_fee_current_dues(row)} onChange={e => handleAmountChange(e)} className='form-control deposit_input' style={{ width: '120px' }} />
                        </p>
                    </div>
                )
            }
        }
    ])
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-success d-inline-flex align-items-center me-2'><i className="ri-secure-payment-fill fs-5 me-2" />Pay</button>
            <Modal className="fade" size='lg' centered={true} backdrop="static" show={status} onHide={toggleModal} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Dues payment - {row_data?.first_name} {row_data?.last_name}</h5>
                    </Modal.Title>
                </Modal.Header>
                <form className='d-block d-lg-block' onSubmit={handlePaymentSubmit}>
                    <Modal.Body>
                        <TableResponsive
                            data={row_data.due_fee.filter(dues => ((dues.imposed_amount * dues.total_installments) - (dues?.fees_payment?.reduce((acc, f) => acc + f?.paid_amt, 0))) > 0)}
                            columns={tableColumns}
                            isPagination={false}
                            showFilter={false}
                            showCustomOptionPage={false}
                            isShowingPageLength={false}
                        />
                        <Row>
                            <Col xs={6}>
                                <label htmlFor="" className='control-label'>Total payable Amount</label>
                                <input className='form-control' id='total_payable_amount' disabled />
                            </Col>
                            <Col xs={6}>
                                <label htmlFor="" className='control-label'>Payment Mode</label>
                                <CustomSelect name="payment_mode" options={[
                                    { label: 'Cash', value: 'cash' },
                                    { label: 'UPI', value: 'upi' },
                                    { label: 'Online (NEFT,RTGS,Online banking)', value: 'online' },
                                    { label: 'Cheque', value: 'cheque' },
                                    { label: 'Other', value: 'other' },
                                ]} />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className='btn btn-success'>pay</button>
                    </Modal.Footer>
                </form>
                <form className='d-none d-lg-none' onSubmit={handlePaymentSubmitListView}>
                    <Modal.Body>
                        <ul className="list list-group list-group-flush mb-0">
                            {row_data.due_fee
                                .filter(dues => ((dues.imposed_amount * dues.total_installments) - (dues?.fees_payment?.reduce((acc, f) => acc + f?.paid_amt, 0))) > 0)
                                .map((dues, index) => (
                                    <li key={index} className="list-group-item ">
                                        <div className="d-flex">
                                            <div className="flex-grow-1" data-id="1">
                                                <h5>{dues.name}</h5>
                                                <p>Current Dues : {given_fee_current_dues(dues)}</p>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <div>
                                                    <input
                                                        type='number'
                                                        id={'deposit_list_' + dues.id}
                                                        step={0.01}
                                                        defaultValue={given_fee_current_dues(dues)}
                                                        className='form-control' style={{ width: '120px' }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className='btn btn-success'>pay</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default FeesPaymentModal