import React from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb"
import { Button, Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap'
import { TableResponsive } from '../../../components/common/TableResponsive'

function TransferCertificate() {
    return (
        <>
            <BreadCrumb title="Transfer Certificate" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Transfer Certificate</h5>
                            <Button className='btn btn-soft-success'>+ Add New</Button>
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={true} columns={[]} data={[]} noRecord="No Record Found" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default TransferCertificate