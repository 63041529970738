import React, { useMemo } from 'react'
import BreadCrumb from '../../../components/common/BreadCrumb'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { TableResponsive } from '../../../components/common/TableResponsive'
import { usePageInitialtor } from '../../../helper/custom_hook'
import { examination } from '../../../helper/api_url'
import { printReportCard } from '../../../helper/print_report_card'

export const Report = () => {
    const {tableData,apiHandler,tableLoading} = usePageInitialtor(examination);
    const {handleDelete} = apiHandler;
    const columns = useMemo(()=>[
        {
            Header: "Name",
            accessor: "name",
            HeaderClass:'text-center',
            DataClass:'text-center',
        },
        {
            Header: "Roll No",
            accessor: "rollNo",
            HeaderClass:'text-center',
            DataClass:'text-center',
        },
        {
            Header: "Class",
            accessor: "class",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:(cell)=>cell.row.original.class + ' ('+cell.row.original.section + ')'
        },
        {
            Header: "Parent/Gardian",
            accessor: "parentName",
            HeaderClass:'text-center',
            DataClass:'text-center',
        },
        {
            Header: "Action",
            accessor: "id",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:(cell)=>{
                return (
                    <>
                        <button onClick={()=>handlePrintReportCard(cell.row.original.marks,{
                            name:cell.row.original.name,
                            class:cell.row.original.class,
                            section:cell.row.original.section,
                            rollNo:cell.row.original.rollNo,
                            parentName:cell.row.original.parentName
                        })} className='btn btn-sm btn-soft-success me-2'>Print</button>
                        <Button onClick={()=>handleDelete({name:cell.row.original.name,id:cell.row.original.id})} className="btn btn-sm btn-soft-danger me-1" >
                            <i className="ri-delete-bin-fill" />  
                        </Button>
                    </>
                )
            }
        },
    
    ],[]);
    const handlePrintReportCard = (marks,studentDetails)=>{
        marks = JSON.parse(atob(marks));
        const reportCard = {studentDetails,marks}
        printReportCard(reportCard);
    }

  return (
    <>
    <BreadCrumb title="Student's Report" prevPage="Home" prevPath="/dashboard"   />
    <Row>
        <Col xs={12}>
            <Card>
                <CardHeader className="d-flex align-items-center justify-content-between">
                    <h5 className="card-title mb-0">Report List</h5>
                    <Link to="/createreport" className='btn btn-soft-success add-btn waves-effect'><i className="ri-add-line align-bottom me-1"></i><span>New Report</span></Link>
                </CardHeader>
                <CardBody className="">   
                        <TableResponsive isLoading={tableLoading} columns={columns} data={tableData} noRecord="No Record Found"  />
                </CardBody>
            </Card>
        </Col>
    </Row>
    </>
  )
}
