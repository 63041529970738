import React, { useEffect, useMemo} from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb";
import { Card, CardBody, CardHeader, Col, Row, Button } from "react-bootstrap";
import { TableResponsive } from "../../../components/common/TableResponsive";
import { ViewCourseModal } from './ViewCourseModal';
import { NewCourseModal} from './NewCourseModal';
import { UpateCourseModal} from './UpateCourseModal';
import { course, fee} from '../../../helper/api_url';
import { usePageInitialtor } from '../../../helper/custom_hook';
import { AddFeesModal } from './AddFeesModal';
import AddFeeToStudentsModal from './AddFeeToStudentsModal';
import { useNavigate } from 'react-router-dom';
function Course() {
    const {tableData,apiHandler} = usePageInitialtor(course);
    const navigate = useNavigate();
    const handleCourseDelete = (courseId,name) =>apiHandler.handleDelete({name:name,id:courseId});
    const navigateToClassProfile  =  (id) => navigate('/classprofile/'+id)
    const columns = useMemo(()=>[
        {Header: "SrNo",accessor: "id", Cell: cell => {return (<p className="">{cell.row.index+1}</p>)},HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Class Name",accessor: "name",HeaderClass:'text-center', DataClass:''},
        {Header: "Section",HeaderClass:'text-center', DataClass:'text-center',Cell:cell=>cell.row.original.section.toUpperCase()},
        {Header: "Code",accessor: "code",HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Type",accessor: "type",HeaderClass:'text-center', DataClass:'text-center'},
        {
            Header: "Total Fees",
            HeaderClass:'text-center', 
            DataClass:'text-center',
            Cell:cell=>{

                const row = cell.row.original;
                return (<span className='badge bg-success p-1 mx-1'>
                    {fee.total_installments}
                    <i className='bx bx-rupee ps-1' />
                    {(row.fees.reduce((acc,fee)=>acc+parseFloat(fee.amount),0)).toFixed(2)}
                </span>);
            }
        },
        {
            Header: "Fees Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:(cell)=>{
                const row = cell.row.original;
                return (
                    <>
                        <AddFeeToStudentsModal data={row} />
                        <AddFeesModal data={row} apiHandler={apiHandler}/>
                    </>
                )
            }
        },
        {
            Header: "Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
              return ( 
                <div className="">
                    <button onClick={e=>navigateToClassProfile(cell.row.original.id)} className='btn btn-sm btn-soft-info me-1'>
                        <i className="ri-eye-fill" />
                    </button>
                    <UpateCourseModal data={cell.row.original} apiHandler={apiHandler} />
                    <Button onClick={()=>{handleCourseDelete(cell.row.original.id,cell.row.original.type)}} className="btn btn-sm btn-soft-danger me-1" >
                        <i className="ri-delete-bin-fill" />  
                    </Button>
                </div>
              )
            },
        },
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                const bs_colors = ['primary','info','warning','danger','success','light','secondary'];
                const badge_class = row.id<=6 ? `badge-outline-${bs_colors[row.id]}`:`badge-S-${bs_colors[row.id%6]}`;
                return (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                            <ViewCourseModal view={row}>
                                <a href="#">{row.name}</a>
                            </ViewCourseModal>
                            <span className={`badge ${badge_class} px-1 ms-1`} >{row.code}</span>
                        </h5>
                        <p className="text-muted mb-0">
                            {row.type} | For : {row.semesters} Semester</p>
                    </div>
                    <div className="flex-shrink-0">
                        <div>
                        <button onClick={e=>navigateToClassProfile(row.id)} className='btn btn-sm btn-soft-info me-1'>
                        <i className="ri-eye-fill" />
                    </button>
                        <UpateCourseModal data={row} apiHandler={apiHandler} />
                            <button onClick={()=>{handleCourseDelete(row.id,row.type)}} className="btn btn-sm btn-soft-danger me-1" data-id="1"> <i className="ri-delete-bin-fill"></i> </button>
                        </div>
                    </div>
                </div>
                )
            }
        }
    ]);
    return (
        <>
            <BreadCrumb title="Users" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Class List</h5>
                            <NewCourseModal apiHandler={apiHandler} />
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive columns={columns} data={tableData} noRecord="No Record Course List" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default Course