import React, { useEffect, useMemo, useState } from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb"
import { Button, Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap'
import { TableResponsive } from '../../../components/common/TableResponsive'
import {examination, invoice} from '../../../helper/api_url'
import { Navigate, useNavigate } from 'react-router-dom';
import { UpdateExamModal } from './Modals/ExamModals/UpdateExamModal';
import { ViewExamModal } from './Modals/ExamModals/ViewExamModal';
import { ViewQuestionModal } from './Modals/QuestionModals/ViewQuestionModal';
import Swal from "sweetalert2";
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { AddQuestionModal } from './Modals/QuestionModals/AddQuestionModal';
import { ExamModal } from './Modals/ExamModal'
import { usePageInitialtor } from '../../../helper/custom_hook'
import { AddExamModal } from './Modals/ExamModals/AddExamModal'

function Examination() {
    // const [data,setData] = useState([])
    const dispatch = useDispatch();
    const {tableData,setTableData,tableLoading,apiHandler,setTableLoading} = usePageInitialtor(examination);
    // const [tableData,setTableData] = useState([]);
    // const [tableLoading,setTableLoading] = useState(true);
    const [showModal,setShow]=useState(false)
    const [showExam,setShowExam] = useState(true)
    const navigate = useNavigate()

    // const {handleDelete}= apiHandler


    const handleExamDelete = (data) =>
        {
            console.log('delete',data)
            apiHandler.handleDelete({name:data.name,id:data.id})
};




    const setExam=(data)=>{
        console.log('open exam modal')
        navigate(`/set-examination?name=${data.name}`)
        
    }


        
    const columns = useMemo(()=>[
        {
            Header: "Exam name",
            accessor: "name",
            HeaderClass:'text-center',
            DataClass:'text-center',
        },
        {
            Header: "Fees",
            accessor: "fees",
            HeaderClass:'text-center',
            DataClass:'text-center',
        },
        {
            Header: "Start Date",
            accessor: "start_date",
            HeaderClass:'text-center',
            DataClass:'text-center',
        },
        {
            Header: "End Date",
            accessor: "end_date",
            HeaderClass:'text-center',
            DataClass:'text-center',
        },
        {
            Header: "Actions",
            accessor: "",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
                return ( 
                  <div className='widthFix'>
                    
                      <ViewExamModal
                      data={cell.row.original}
                        />
                      <UpdateExamModal
                      data={cell.row.original} 
                      apiHandler={apiHandler}
                       />
                      {/* <Button 
                      onClick={()=> setExam(cell.row.original)} 
                          className="btn btn-sm btn-soft-danger me-1" >
                      
                          <i class="ri-book-open-fill"></i>
                      </Button>                  */}
                       
                      <Button 
                      onClick={()=> handleExamDelete(cell.row.original)} 
                          className="btn btn-sm btn-soft-danger me-1" >
                          <i className="ri-delete-bin-fill" />  
                      </Button>
                  </div>
                )
              },
          
        },
        {
            Header: "Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
              return ( 
                <div className='widthFix'>
                           <button
                            onClick={()=>setExam(cell.row.original)}
                            className='btn btn-soft-success'>+ Set Paper</button>
                           <button 
                        //    onClick={toggleModal} 
                           className='btn btn-soft-success'>Routine</button>
                </div>
              )
            },
        }
    ]);

const handleShow=()=>{
    console.log('open modal')
    setShow(true)
}

    return (
        <>
            <BreadCrumb title="Examination" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Examination</h5>
                            {/* <AddExamModal setTableData={setTableData} data={tableData} setShow={setShow} show={showModal} /> */}
                            {/* <ExamModal fetchCourses={fetchCourses} setTableData={setTableData} data={tableData} setShowExam={setShowExam} show={showExam} /> */}
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={true} columns={columns} data={tableData} noRecord="No Record Found" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Examination