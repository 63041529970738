import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { allUser, examination } from '../../../../../helper/api_url';
import { useModalHandler } from '../../../../../helper/custom_hook';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../../../features/Ui/uiSlice';
import { swal } from '../../../../../helper/swal';
import CustomSelect from '../../../../../components/CustomSelect';

export function AddQuestionModal({ data, apiHandler,setTableData ,fetchCourses,setShow}) {
    const { toggleModal, status } = useModalHandler();

    const dispatch = useDispatch()

 

    const handleSubmit=(e)=>{
        e.preventDefault()
        const formData = new FormData(e.target)
        dispatch(setPreloader({loader:true,message:'adding Question please wait ..'}))
        examination.createQuestion(formData)
        .then(res=>{
            // console.log('response>>',res.data.question)
            fetchCourses()
            console.log('status',status)
            toggleModal()
            // setShow(false)
            // setTableData(data=> data.push(res.data.data.question))
        }).catch(e=>swal.error(e.response?e.response?.data?.message:e.message))
        .finally(()=>dispatch(setPreloader({loader:false,message:''})))
    }

    // const onChangeInSelect = event => setPageSize(Number(event.value))

    return (
        <>
            <button onClick={toggleModal} className='btn btn-soft-success'>+ Add New</button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Add Course</h5></Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="row g-3">
                        <div className="col-6">
                                <div>
                                    <label htmlFor="course_name" className="form-label">Course Name</label>
                                    <input type="text" className="form-control" id='course_name' name="name"  placeholder="Enter course name" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="course_subject" className="form-label">Course Subject</label>
                                    <input type="text" className="form-control" id='course_subject' name="subject"  placeholder="Enter course subject" />
                                </div>
                            </div>   
                            <div className="col-6">

                                <div>
                                    <label htmlFor="class_id" className="form-label">Class</label>
                                    <CustomSelect options={[
                                    {label:'2',value:2},
                                    {label:'4',value:4},
                                    {label:'8',value:8},
                                    {label:'10',value:10},
                                    {label:'20',value:20},
                                    {label:'30',value:30},
                                    {label:'40',value:40},
                                    {label:'50',value:50},
                                ]}
                                //  onChange={onChangeInSelect} 
                                 name="class_id" />
                                </div>
                            </div>  
                            <div className="col-12">
                                <div>
                                    <label htmlFor="course_question" className="form-label">Course Question</label>
                                    <input style={{ height: '200px',textAlign:'center' }} type="text" className="form-control" id='course_question' name="question" defaultValue={data.course_question} placeholder="Enter course question" />
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-lg-12">
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
