import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import ModalProfileImage from '../../../../../components/common/ModalProfileImage';
import { useModalHandler } from '../../../../../helper/custom_hook';

export function ViewExamModal(data) {
    const { toggleModal, status } = useModalHandler();
    const navigate = useNavigate();



    return (
        <>
            {data.children ? (
                <div onClick={toggleModal}>{data.children}</div>
            ) : (
                <button onClick={toggleModal} className='btn btn-sm btn-soft-info me-1'>
                    <i className="ri-eye-fill" />
                </button>
            )}
            <Modal className="fade" centered={true} show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>View {data.data.name} Details</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='position-relative'>
                        <div className="row g-3">

                            <div className="col-6">
                                <div>
                                    <label htmlFor="courseName" className="form-label">Exam Name</label>
                                    <input type="text" className="form-control" id='name' value={data.data.name} readOnly />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="courseSubject" className="form-label">Fees</label>
                                    <input type="text" className="form-control" id='fees' value={data.data.fees} readOnly />
                                </div>
                            </div>

                            <div className='col-12'>
                                <label htmlFor="courseQuestion" className="form-label">Description</label>
                                <textarea style={{ height: '200px' }} id="description" className='form-control' value={data.data.description} readOnly></textarea>
                            </div>
                            <div className="col-12">
                                <div>
                                    <label htmlFor="courseCreatedName" className="form-label">Start Date</label>
                                    <input type="text" className="form-control" id='start_date' value={data.data.start_date} readOnly />
                                </div>
                            </div>
                            <div className="col-12">
                                <div>
                                    <label htmlFor="courseCreatedName" className="form-label">End Date</label>
                                    <input type="text" className="form-control" id='end_date' value={data.data.end_date} readOnly />
                                </div>
                            </div>
                            <div style={{display:'none'}} className="col-12">
                                <div>
                                    <label htmlFor="courseCreatedName" className="form-label">Exam id</label>
                                    <input type="text" className="form-control" id='exam_id' value={data.data.id} readOnly />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
