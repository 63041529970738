import { APPNAME, ASSETURL } from '../config';
import {post,get, del,put, use,API_URL} from './api_helper';
export const ASSET_URL = ASSETURL;
export const APP_NAME= APPNAME;

export const auth={
    login: data =>post("/login",data),
    validate:()=>get('/validate'),
    logout:()=>get("/logout"),
}

export const allUser={
    users:(data)=>post("/user",data),
    changeStatus:(id)=>get(`/user/${id}/toggle/is_active`),
    roles:()=>get("/roles"),
    list: () =>get("/users"),
    // delete:(id)=>del(`/user/${id}`),
    delete:(id)=>get(`/user/${id}/toggle/is_deleted`),
    update:(data)=>post("/user/update",data),
}
export const staff = {
    users:(data)=>post("/user",data),
    list :()=> get("/users/type/staff"),
    roles:()=>get("/roles"),
    changeStatus:(data)=>get(`/user/${data}/toggle/is_active`),
    delete:(id)=>del(`/user/${id}`)
}
export const student = {
    add:(data)=>post("/admission",data),
    otherDetails:data=>post('/user/personal-details',data),
    list :()=> get("/users/type/student"),
    roles:()=>get("/roles"),
    changeStatus:(data)=>get(`/user/${data}/toggle/is_active`),
    delete:(id)=>del(`/user/${id}`),
    enroll:(data)=>post(`/enrollment`,data),
    profile:(id)=>get(`/users/${id}/profile`)
}
export const family = {
    add:(data)=>post('/family',data),
    getAll:()=>get('/family'),
    get:id=>get(`/family/${id}`),
    update:data=>post('/update-family',data),
    delete:id=>del(`/family/${id}`)
}
export const userAddress={
    add:(data)=>post("/user/address",data),
}
export const userQualification={
    add:(data)=>post("/user/qualification",data),
}
export const teacher = {
    users:(data)=>post("/user",data),
    list :()=> get("/users/type/teacher"),
    roles:()=>get("/roles"),
    changeStatus:(data)=>get(`/user/${data}/toggle/is_active`),
    delete:(id)=>del(`/user/${id}`)
}
export const visitor = {
    users:(data)=>post("/user",data),
    list :()=> get("/users/type/visitor"),
    roles:()=>get("/roles"),
    changeStatus:(data)=>get(`/user/${data}/toggle/is_active`),
    delete:(id)=>del(`/user/${id}`)
}
export const enquiry={
    list:()=>get("/enquiries"),
    fetchSingle:(id)=>get(`/enquiry/${id}`),
    add:(data)=>post(`/enquiry`,data),
    update:(data)=>post('/enquiry/update',data),
    delete:(id)=>del(`/enquiry/${id}`),
    addFollow:(data)=>post('/enquiry/add-followup',data),
}
export const followup = {
    pending:(date)=>get(`followups/pending/${date}`)
}

export const course={
    list:()=>get("/courses"),
    select:(id)=>get(`/course/${id}`),
    students:(course_id)=>get(`/students/${course_id}`),
    add:(data)=>post(`/course`,data),
    addFee:(cid,fid)=>get(`/course/${cid}/fees/${fid}`),
    update:(data)=>post(`/course/update`,data),
    delete:(id)=>del(`/course/${id}`)
}
export const batch={
    add:(data)=>post(`/course/batch`,data),
    list:()=>get(`/batches`),
    update:(data)=>post(`/update/batch`,data),
    delete:(id)=>del(`/batch/${id}`)
}
export const role={
    list:()=>get("/roles"),
    add:(data)=>post(`/role`,data),
    update:(data)=>post(`/role/change-priority`,data),
    delete:(id)=>del(`/role/${id}`)
}
export const fee = {
    list:()=>get("/fees"),
    fetchSingle:(id)=>get(`/fee/${id}`),
    add:(data)=>post(`/fee`,data),
    update:(data)=>post(`/fee/update`,data),
    delete:(id)=>del(`/fee/${id}`),
    dueslist:()=>get(`/fees/dues-list`),
    courseDuesList:(course_id)=>get(`/course-due/${course_id}`),
    impose_to_class_users:(data)=>post(`/impose-fee/allStudent`,data),
    impose_to_users:(data)=>post(`/impose-fee/student`,data),
    payment:(data)=>post('/fees-payment',data),
    StudentInfoFee:(sid)=>get(`/student/${sid}/fee`),
}

export const support = {
    addIssue:data=>post(`/issue`,data),
    list:data=>get(`/issues`,data),
    markProgress:id=>get(`/issue/${id}/seen`),
    markResolved:id=>get(`/issue/${id}/resolve`),
    delete:id=>del(`/issue/${id}`),
    comment:(data)=>post('/issue/comment',data),
    commentlist:(pid)=>get(`/issue/${pid}/comments`),
    Commentdelete:(id)=>del(`/issue/comment/${id}`),
}
export const questions = {

}

export const examination = {
    createQuestion:(data)=>post('/question-bank',data),
    readQuestions:(filters)=>post('/questions',filters),
    deleteQuestion:(id)=>del('/question-bank/'+id),
    updateQuestion:(data)=>post('/update-question-bank',data),


    // storeReport:(data) => post('/report-card',data),
    // list:() => get('/report-cards'),
    // getReportCard:(id) => get('/report-card/'+id),
    // delete:(id) => del('/report-card/'+id),
    create:(data)=>post('/exam',data),
    update:(data)=>post('/exam-update',data),
    list:() => get('/exams'),
    select:(id) => get('/exams/'+id),
    delete:(id) => del(`/exam/${id}`),
}
export const homeworks = {
    list:()=>get(),
    delete:()=>del(),
    select:()=>get(),

}

export const attendance = {
    add: (data)=> post(`/attendance`,data),
    list:(filter)=>post('/get-attendance',filter),
    list_by_batch:(batch_id,month,year='')=>get(`/attendance/${batch_id}/${month}/${year}`),
}

export const dashboard = {
    data:(filter)=>post('/dashboard',filter),
}
export const invoice = {
    list:()=>get('/invoice'),
    read:(id)=>get('/invoice/'+id),
    delete:(id)=>del('/invoice/'+id),
}

export const homework={
    add:(data)=>post('/homework',data),
    list:(filter)=>post('/homeworks-byfilter',filter),
    delete:(homeId,class_id)=>del(`/homeworks-remove-form-class/${homeId}/${class_id}`),
}