import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export const printReportCard = (reportCard)=>{
    const doc = new jsPDF('p','mm','a4');
    let count = 1;
    let totalFullMarks = reportCard.marks.filter(i=>i.obtainedMark!=="").reduce((a,m)=>a+parseFloat(m.fullMark),0);
    let totalPassingMarks = reportCard.marks.filter(i=>i.obtainedMark!=="").reduce((a,m)=>a+parseFloat(m.passingMark),0);
    let totalObtainedMarks = reportCard.marks.filter(i=>i.obtainedMark!=="").reduce((a,m)=>a+parseFloat(m.obtainedMark),0);
    let divider = "---------------------------------------------------------------------------------------------------------------------------------------------------------";
    let tableHeadings=[
        {content:'SL',styles:{halign:'left'}},
        {content:'Subject',styles:{halign:'center'}},
        {content:'Full Marks',styles:{halign:'center'}},
        {content:'Passing Marks',styles:{halign:'center'}},
        {content:'Obtained Marks',styles:{halign:'center'}},
    ];
    doc.autoTable({
        head: [
            [{ content: " Gurukul Acedamy Logo", colSpan: tableHeadings.length, styles: { 
                halign: 'center', 
                cellPadding: 5 ,
                fillColor:[225,225,225],
                font: 'mono'
            } }],
            [{ content: "Gurukul Acedamy", colSpan: tableHeadings.length, styles: { halign: 'center' } }],
            [{ content: `School Address City 110092`, colSpan: tableHeadings.length, styles: { halign: 'center' } }]
        ],
        body:[
            [{ 
                content: divider, 
                colSpan: tableHeadings.length, 
                styles: { halign: 'center' } 
            }],
            [
                { content:"Student Name : " + reportCard.studentDetails.name, colSpan: 3, styles: { halign: 'left' } },
                { content:"Student Photo ", colSpan: 2, rowSpan:4, styles: { halign: 'center',valign:'center',fillColor:[225,225,225] } }
            ],
            
            [{ content:`class : ${reportCard.studentDetails.class} (${reportCard.studentDetails.section})`, colSpan: 3, styles: { halign: 'left' } }],
            [{ content:`Roll No. : ${reportCard.studentDetails.rollNo}`, colSpan: 3, styles: { halign: 'left' } }],
            [{ content: "Parent/Gardian Name : " + reportCard.studentDetails.parentName, colSpan: 3, styles: { halign: 'left' } }],
        ],
        startY: 10,
        theme: 'plain',
    });
    doc.autoTable({
        head:[tableHeadings],
        body:reportCard.marks.filter(i=>i.obtainedMark!=="").map(d=>[
            { content:count++,styles: { halign: 'left' }},
            { content:d.subjectDisplayName,styles: { halign: 'center' }},
            { content:d.fullMark,styles: { halign: 'center' }},
            { content:d.passingMark,styles: { halign: 'center' }},
            { 
                content:d.obtainedMark,
                styles: parseFloat(d.obtainedMark) >= parseFloat(d.passingMark)
                ?{ halign: 'center'}
                :{ halign: 'center', textColor: [255, 0, 0] }
            }
        ]),
        startY:80,
        startX:10,
        theme: 'striped',
        foot: [
            [
                { content: 'Total', styles: { halign: 'center' }, colSpan: 2 },
                { content: totalFullMarks, styles:{halign:'center'}},
                { content: totalPassingMarks, styles:{halign:'center'}},
                { 
                    content: totalObtainedMarks, 
                    styles: parseFloat(totalObtainedMarks) >= parseFloat(totalPassingMarks)
                    ?{ halign: 'center'}
                    :{ halign: 'center', textColor: [255, 0, 0] }
                }
            ],
        ],
    });
    doc.save(reportCard.studentDetails.name+'_MarkSheet.pdf');
}