import React from 'react'
import { Modal } from 'react-bootstrap';
import { useModalHandler, usePageInitialtor } from '../../../helper/custom_hook';
import CustomSelect from '../../../components/CustomSelect';
import { userAddress } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { useDispatch } from 'react-redux';
export const PermanentAddress = ({data,setUserData}) => {
    const dispatch=useDispatch();
    const {toggleModal,status} = useModalHandler();
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setPreloader({ loader: true, message: 'please wait...' }))
        const formdata = new FormData(e.target);
        userAddress.add(formdata)
            .then((res) => {
                setUserData((userData) => {
                    const personal_details = userData.personal_details;
                    const addresses = userData.addresses == null ? []:userData.addresses;
                    res.data.address.type == 'permanent' ? personal_details.permanent_address = res.data.address:personal_details.curremt_address = res.data.address;
                    addresses.push(res.data.address);
                    return { ...userData,addresses:[...addresses], personal_details: { ...personal_details } };
                });
                swal.success(res.data.message);
                e.target.reset();
                dispatch(setPreloader({ loader: false, message: '' }));
            })
            .catch((err) => {
                swal.error(err.response ? err.response.data.message : err.message);
                dispatch(setPreloader({ loader: false, message: '' }));
            });
    };
    
  return (
    <>   
            <button type='button' onClick={toggleModal}  className="btn btn-sm btn-success btn-end"><i className="bx bx-plus"></i> Add Address</button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Create Address</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit}>
                        <div className="row g-3">
                            <div className="col-12">
                                <input type="hidden" name="user_id" id='user_id' defaultValue={data&&data.id} />
                                <div>
                                    <label htmlFor="type" className="form-label">Type</label>
                                    <CustomSelect name="type" id="type" options={[
                                            { value: 'current', label: 'current' },
                                            { value: 'permanent', label: 'permanent' },
                                            { value: 'home', label: 'Home' },
                                            { value: 'office', label: 'Office' },
                                            { value: 'other', label: 'Other' },
                                        ]} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div>
                                    <label htmlFor="addr1" className="form-label">Address 1</label>
                                    <textarea  name='addr1'  id='addr1' className='form-control' />
                                </div>
                            </div>
                            <div className="col-12">
                                <div>
                                    <label htmlFor="addr2" className="form-label">Address 2</label>
                                    <textarea  name='addr2'  id='addr2' className='form-control' />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="city" className="form-label">City</label>
                                    <input type='text' name='city' id='city' className='form-control' />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="district" className="form-label">District</label>
                                    <input type='text' name='district' id='district' className='form-control' />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="state" className="form-label">State</label>
                                    <input type='text' name='state' id='state' className='form-control' />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="pin" className="form-label">Pin</label>
                                    <input type='number' name='pin' id='pin' className='form-control' />
                                </div>
                            </div>
                           
                            <div className="col-lg-12 mt-4">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
    </>
  )
}
