import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import ModalProfileImage from '../../../../../components/common/ModalProfileImage';
import { useModalHandler } from '../../../../../helper/custom_hook';

export function ViewQuestionModal(data) {
    const { toggleModal, status } = useModalHandler();
    const navigate = useNavigate();



    return (
        <>
            {data.children ? (
                <div onClick={toggleModal}>{data.children}</div>
            ) : (
                <button onClick={toggleModal} className='btn btn-sm btn-soft-info me-1'>
                    <i className="ri-eye-fill" />
                </button>
            )}
            <Modal className="fade" centered={true} show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>View {data.data.course_name} Details</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='position-relative'>
                        <div className="row g-3">

                            <div className="col-6">
                                <div>
                                    <label htmlFor="courseName" className="form-label">Course Name</label>
                                    <input type="text" className="form-control" id='courseName' value={data.data.course.name} readOnly />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="courseSubject" className="form-label">Subject</label>
                                    <input type="text" className="form-control" id='courseSubject' value={data.data.subject} readOnly />
                                </div>
                            </div>

                            <div className='col-12'>
                                <label htmlFor="courseQuestion" className="form-label">Question</label>
                                <textarea style={{ height: '200px' }} id="courseQuestion" className='form-control' value={data.data.question} readOnly></textarea>
                            </div>
                            <div className="col-12">
                                <div>
                                    <label htmlFor="courseCreatedName" className="form-label">Created By</label>
                                    <input type="text" className="form-control" id='courseCreatedName' value={data.data.creator_name} readOnly />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
