import React, { useEffect } from 'react'
import { Modal} from 'react-bootstrap'
import { allUser, examination } from '../../../../../helper/api_url';
import {  ASSET_URL } from '../../../../../helper/api_url'
import Camera from '../../../../../components/common/Camera';
import SimpleBar from 'simplebar-react';
import ModalProfileImage from '../../../../../components/common/ModalProfileImage';
import { useModalHandler, useWebCam } from '../../../../../helper/custom_hook';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../../../features/Ui/uiSlice';
import { swal } from '../../../../../helper/swal';

export function UpdateQuestionModal({ data, apiHandler,setTableData }) {
    const { genders, toggleModal, status, userProfile, setUserProfile } = useModalHandler();
    const { webcam, toggleWebcam, setCapturedImageToInput } = useWebCam(setUserProfile);
    const dispatch= useDispatch();


    // useEffect(() => {
    //     // You can add any side effects you need here
    // }, [data]);
    const handleQuestionSubmit = e => {
        console.log('tttt',e)
        e.preventDefault();
        const formData = new FormData(e.target);
        console.log('formdata',formData)
        dispatch(setPreloader({loader:true,message:'updating Question please wait ..'}))
        examination.updateQuestion(formData)
        .then(res=>
            // console.log('response >>',res)
            // setData(state=>{
            //     return state.map(s=>{
            //         if(s.id === res.data.question.id) return res.data.question
            //         return s
            //     })
            // })
            setTableData(state=>state.map(s=>s.id===res.data.question.id?res.data.question:s))
         )
        .catch(e=>swal.error(e.response?e.response?.data?.message:e.message))
        .finally(()=>dispatch(setPreloader({loader:false,message:''})))
    }

    return (
        <>       
            <button onClick={toggleModal} className='btn btn-sm btn-soft-success me-1'><i className="ri-pencil-fill"></i></button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Update Question</h5></Modal.Title>
                </Modal.Header>
                <form onSubmit={handleQuestionSubmit}>
                    <SimpleBar id="scrollbar" style={{ maxHeight: "calc(90vh - 100px)" }}>
                        <Modal.Body>
                            <div className='position-relative'>
                                {webcam ? (
                                    <div className='position-absolute top-0 bottom-0 d-flex justify-content-center align-items-center start-0 end-0 modal-content border-0' style={{ zIndex: 9 }}>
                                        {/* Replace Camera component with appropriate component */}
                                        {/* <Camera onSave={(file) => { setCapturedImageToInput(file, document.getElementById('avatarInput')); toggleWebcam() }} onClose={() => toggleWebcam()} /> */}
                                    </div>
                                ) : null}
                                <div className="row g-3">
                                    {/* {userProfile && <ModalProfileImage viewimage={userProfile} />} */}
                                    {/* Assuming your fields like course_created_name, course_name, etc., are displayed similarly */}

                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="courseName" className="form-label">Course Name</label>
                                            <input type="text" className="form-control" id='name' name="name" defaultValue={data.course.name} placeholder="Enter course name" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="courseCreatedName" className="form-label">Course Subject</label>
                                            <input type="text" className="form-control" id='creator_name' name="subject" defaultValue={data.subject} placeholder="Enter course created name" />
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-12">
                                        <label htmlFor="courseSubject" className="form-label">Course Subject</label>
                                        <input type="text" id="courseSubject" name='course_subject' defaultValue={data.course_subject} className='form-control' />
                                    </div> */}
                                    <div className='col-lg-12'>
                                        <label htmlFor="courseQuestion" className="form-label">Course Question</label>
                                        <textarea style={{ height: '200px' }} id="question" name='question' defaultValue={data.question} className='form-control'></textarea>
                                    </div>
                                    <div style={{display:'none'}}>
                                        <label htmlFor="courseQuestion" className="form-label">Course Id</label>
                                        <textarea style={{ height: '200px' }} id="class_id" name='class_id' defaultValue={data.course_id} className='form-control'></textarea>
                                    </div>
                                    <div style={{display:'none'}}>
                                        <label htmlFor="courseQuestion" className="form-label">Course Id</label>
                                        <textarea style={{ height: '200px' }} id="question_id" name='question_id' defaultValue={data.id} className='form-control'></textarea>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </SimpleBar>
                    <Modal.Footer>
                        <div className="col-lg-12">
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}
