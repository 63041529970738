import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { swal } from '../../../helper/swal';
import { fee, student } from '../../../helper/api_url';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'react-bootstrap';
import { TableResponsive } from '../../../components/common/TableResponsive';
import { formatDate } from '../../../helper/formatDate';

export const StudentDuesFees = () => {
    const { sid } = useParams();
    const dispatch = useDispatch();
    const [studentFeeInfo, setStudentFeeInfo] = useState([]);
    const [studentData, setStudentData] = useState(null);
    useEffect(() => {
        ; (async () => {
            try {
                dispatch(setPreloader({ loader: true, message: 'Loading Please Wait ....' }));
                const res = await student.profile(sid);
                setStudentData(res.data.profile);
                const data = await fee.StudentInfoFee(sid);
                setStudentFeeInfo(data.data.student_fee_info);
            } catch (err) {
                swal.error(err.response ? err.response.data.message : err.message);
            } finally {
                dispatch(setPreloader({ loader: false, message: '' }));
            }
        })();
    }, []);
    const columns = useMemo(() => [

        {
            Header: "Fees name",
            accessor: "name",
            HeaderClass: '',
            DataClass: '',
        },
        {
            Header: "Total Amount",
            accessor: "imposed_amount",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
        },
        {
            Header: "Paid Amount",
            accessor: "fees_payment",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: ({ row }) => {
                const feesPayment = row.original.fees_payment;
                if (feesPayment && feesPayment.length > 0) {
                    return (
                        <>
                            {feesPayment.map((payment, index) => (
                                <span className='badge badge-outline-success mx-1' key={index}>{payment.paid_amt}</span>
                            ))}
                        </>

                    );
                }
                return "-";
            }
        },
        {
            Header: "Total Dues",
            accessor: "total_dues",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: ({ row }) => {
                const imposedAmount = row.original.imposed_amount;
                const lastPaymentDateObject = new Date(row.original.last_payment_date);
                const now = Date.now();
                if (lastPaymentDateObject < now){
                    const feesPayment = row.original.fees_payment || [];
                    const totalPaid = feesPayment.reduce((total, payment) => total + (payment.paid_amt || 0), 0);
                    const totalDues = imposedAmount - totalPaid;
                    return  totalDues > 0 ? <span className={'text-danger'}>{totalDues}</span>:<span className='text-success'>Fully Paid</span>;
                }else return "-"


            }
        },
        {
            Header: "Last Date of Payment",
            HeaderClass: 'text-end',
            DataClass: 'text-end',
            Cell: (cell) => {
                return (<span>{formatDate(cell.row.original.last_payment_date)}</span>)
            }
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                const imposedAmount = row.imposed_amount;
                const feesPayment = row.fees_payment || [];
                const totalPaid = feesPayment.reduce((total, payment) => total + (payment.paid_amt || 0), 0);
                const totalDues = imposedAmount - totalPaid;


                return (
                    <div className="d-flex">
                        <div className="flex-grow-1" data-id="1">

                            <h5 className="fs-13 mb-1">
                                <a href="#" className="link text-dark"></a>
                                <a href="#">{row.name}</a>
                            </h5>

                            <p className="text-muted mb-0">
                                T- <span className='badge badge-outline-info mx-1'>{row.imposed_amount}</span>
                                {
                                    feesPayment.map((payment, index) => (
                                        <>
                                            <span className='badge badge-outline-success mx-2' key={index}>P-{payment.paid_amt}</span>
                                        </>
                                    ))
                                }
                            </p>
                        </div>
                        <div className="flex-shrink-0">
                            <div>
                                <span className={totalDues > 0 ? 'text-danger' : 'text-success'}>
                                    Dues- {totalDues > 0 ? totalDues : "Fully Paid"}
                                </span>

                            </div>
                        </div>
                    </div>
                )
            }
        }
    ]);
    return (
        <>
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between" style={{ flexDirection: 'column' }}>
                            <h2>{studentData?.first_name} {studentData?.last_name}</h2>
                            <h5 className="card-title mb-0">Fees Information</h5>
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isPagination={false} showCustomOptionPage={false} columns={columns} data={studentFeeInfo} noRecord="No Record Found" />
                        </CardBody>
                        <CardFooter className='d-flex align-items-center justify-content-between w-100'>
                            <div>
                                <p className='d-inline border me-4 p-2 rounded bg-soft-primary'>
                                    Total Fees : {studentFeeInfo.reduce((a,f)=>a+parseFloat(f.imposed_amount),0)}
                                </p>
                                <p className='d-inline border p-2 rounded bg-soft-success' >
                                    Total Paid : {studentFeeInfo.reduce((acc, fee) => {
                                        const total_payment = fee.fees_payment.reduce((totalPayment, fp) => parseFloat(totalPayment) + parseFloat(fp.paid_amt), 0);
                                        return acc + total_payment;
                                    }, 0)}
                                </p>
                            </div>
                            <p className='border p-2 rounded bg-soft-danger'>
                                Overdues : {
                                    studentFeeInfo.reduce((acc, fee) => {
                                        const lastPaymentDateObject = new Date(fee.last_payment_date);
                                        const now = Date.now();
                                        if (lastPaymentDateObject < now){
                                            const total_payment = fee.fees_payment.reduce((totalPayment, fp) => parseFloat(totalPayment) + parseFloat(fp.paid_amt), 0);
                                            return parseFloat(acc) + parseFloat(fee.imposed_amount) - parseFloat(total_payment);
                                        }else return acc + 0;

                                    }, 0)
                                }
                            </p>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
