import React, { useMemo } from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb"
import { Button, Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap'
import { TableResponsive } from '../../../components/common/TableResponsive'
import { useNavigate } from 'react-router-dom'
import { ASSET_URL, student } from '../../../helper/api_url'
import { usePageInitialtor } from '../../../helper/custom_hook'

function AdmitCard() {
    const navigate = useNavigate();
    const {tableData,setTableData,tableLoading,apiHandler} = usePageInitialtor(student);
    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "first_name",
            HeaderClass: '',
            DataClass: '',
            Cell: (cell) => {
                const imageUrl = ASSET_URL + cell.row.original.avatar;
                return (<span> <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={imageUrl} alt="User Avatar" />{cell.row.original.first_name} {cell.row.original.last_name}</span>)
            }
        },
        {
            Header: "Phone",
            accessor: "phone",
            HeaderClass: '',
            DataClass: '',
        },
        {
            Header: "Email",
            accessor: "email",
            HeaderClass: '',
            DataClass: '',
        },
        {
            Header: "User Type",
            accessor: "role.name",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
        },
        {
            Header: "Class",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: cell => {
                const row = cell.row.original;
                return row.courses[0]?.name
            }
        },
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <div className="">
                        <button onClick={e=>handleNavigateToPrintAdmitCard(row.id)} className='btn btn-success  me-2'>Print Admit Card</button>
                        <button onClick={e=>handleNavigateToPrintDemandBill(row.id)} className='btn btn-warning'>Print Demand Bill</button>
                    </div>
                )
            },
        },
       
    ]);
    const handleNavigateToPrintAdmitCard = id => navigate('/admit-card-print/'+id);
    const handleNavigateToPrintDemandBill = id => navigate('/demand-bill-print/'+id);
    return (
        <>
            <BreadCrumb title="Admit Cards" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Admit Cards</h5>
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={tableLoading} columns={columns} data={tableData} noRecord="No Record Found" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default AdmitCard