import React, { useEffect, useState } from 'react';
import { useModalHandler } from '../../../helper/custom_hook';
import { Modal, Row, Col} from 'react-bootstrap';
import CustomSelect from '../../../components/CustomSelect';
import { fee, student } from '../../../helper/api_url';
import DataLoading from '../../../components/DataLoading';
import { swal } from '../../../helper/swal';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';

function ImposeFeeModal() {
    const {status,toggleModal} = useModalHandler();
    const [isLoading,setIsLoading] = useState(true);
    const [fees,setFees] = useState([]);
    const [students,setStudents] = useState([]);
    const dispatch = useDispatch();
    useEffect(()=>{
        if(status){
            fee.list().then(res=>setFees(res.data.fee)).catch(e=>console.error(e));
            student.list()
            .then(res=>setStudents(res.data.users))
            .catch(e=>console.error(e))
            .finally(()=>setIsLoading(false));
        }
    },[status]);
    const handleFeeImpose = e => {
        e.preventDefault();
        dispatch(setPreloader({loader:true,message:'please wait ... '}));
        const formData = new FormData(e.target);
        fee.impose_to_users(formData).then(r=>swal.success(r.message,'Done'))
        .catch(e=>swal.error(e.response?e.response.data.message:e.message))
        .finally(()=>dispatch(setPreloader({loader:false,message:''})))

    }
    return (
        <>
            <button onClick={toggleModal} className='btn btn-soft-dark me-1'><i className="ri-add-line align-bottom me-1"></i>Impose Fee</button>
            <Modal className="fade" size='md' centered={true} backdrop="static" show={status} onHide={toggleModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Impose A New Fee on Student</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleFeeImpose}>
                    <Modal.Body>
                        {isLoading ? (<DataLoading />) : (
                            <>    
                                <Row>
                                    <Col xs={12} className='mb-2' >
                                        <div>
                                            <label className='control-label'>Select A Student</label>
                                            <CustomSelect name="user_id" options={students.map(s=>({label:s.first_name+" "+s.last_name + " - ("+s.courses[0]?.name+")",value:s.id}))} isSearchable />
                                        </div>
                                    </Col>
                                    <Col xs={12} className='mb-2' >
                                        <div>
                                            <label className='control-label'>Select A Fee</label>
                                            <CustomSelect name="fee_id" options={fees.map(f=>({label:f.name,value:f.id}))} isSearchable />
                                        </div>
                                    </Col>
                                    <Col xs={6} className='mb-2' >
                                        <div>
                                            <label className='control-label'>Fee Amount</label>
                                            <input type='number'  step={0.01} disabled className='form-control' />
                                        </div>
                                    </Col>
                                    <Col xs={6} className='mb-2' >
                                        <div>
                                            <label className='control-label'>Final Amount</label>
                                            <input type='number' name="fee_amount" className='form-control' />
                                        </div>
                                    </Col>
                                    <Col xs={6} className='mb-2' >
                                        <div>
                                            <label className='control-label'>Start Date Amount</label>
                                            <input type='date' name='starting_date'  className='form-control' />
                                        </div>
                                    </Col>
                                    <Col xs={6} className='mb-2' >
                                        <div>
                                            <label className='control-label'>Last Payment Date</label>
                                            <input type='date' name='last_payment_date' className='form-control' />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mt-4">
                                    <div className="alert alert-warning">
                                        <p className="mb-0"><span className="fw-semibold">NOTES: </span>
                                            <span id="note"> Make sure you have already created fees. 
                                            From here you can select a certain fee and then a Student and impose it to selected Student.
                                            All the Fees imposed on selected student can be seen in Dues List (Fee Dues) Section.
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-soft-info btn-label right ms-auto" >
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i> Impose
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default ImposeFeeModal